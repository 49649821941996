import React from 'react'
import { Container } from '@mui/material'

import useViewableScreen from '../../hooks/useViewableScreen.hook'
import Feature from '../feature/Feature.component'

const MainContainer = () => {
  const viewableScreen = useViewableScreen()

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: viewableScreen,
        padding: '4vh',
      }}
    >
      <Feature />
    </Container>
  )
}

export default MainContainer
