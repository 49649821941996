import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/roboto/100.css'
import '@fontsource/roboto/900.css'
import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider } from '@mui/material'
import './index.css';
import App from './App';

const theme = responsiveFontSizes(createTheme({
  palette: {
    background: {
      default: 'black'
    },
    text: {
      primary: 'white'
    }
  },
  typography: {
    fontWeightLight: true,
  }
}))

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);