import { useEffect, useState } from 'react'

const useViewableScreen = () => {
  const [viewableScreen, setViewableScreen] = useState(window.innerHeight)

  useEffect(() => {
    const handleScreenResize = () => {
      setViewableScreen(window.innerHeight)
    }

    window.addEventListener('resize', handleScreenResize)

    return () => {
      window.removeEventListener('resize', handleScreenResize)
    }
  }, [])

  return viewableScreen
}

export default useViewableScreen
