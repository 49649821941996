import React from 'react'

import './App.css'

import MainContainer from './components/containers/MainContainer.component'

function App() {
  return (
    <MainContainer />
  )
}

export default App;
