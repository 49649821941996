import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import Typewriter from 'typewriter-effect'

import titles from '../../config/titles'

import './Feature.component.css'

const Feature = () => {
  const [startSecondLine, setStartSecondLine] = useState(false)

  return (
    <>
      <Box
        sx={{
          border: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2rem',
          width: '100%',
          maxWidth: '1080px',
        }}
        className='feature__section'
      >
        <div>
          <Typography sx={{ fontWeight: 100 }} variant='h4' component='h1'>
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString('Michael Reidy is a...')
                  .stop()
                  .start()
                  .callFunction((state) => {
                    state.elements.cursor.style.display = 'none'
                    setStartSecondLine(true)
                  })
              }}
            />
          </Typography>
        </div>
        {
          <div>
            <Typography sx={{ fontWeight: 900 }} variant='h4' component='h1'>
              {startSecondLine ? (
                <Typewriter
                  options={{
                    strings: titles,
                    autoStart: true,
                    loop: true,
                  }}
                />
              ) : (
                <span>&nbsp;</span>
              )}
            </Typography>
          </div>
        }
      </Box>
    </>
  )
}

export default Feature
